import React from 'react';
import { Building2, Mail, Shield, Smartphone, ExternalLink } from 'lucide-react';

const DragoneroBusiness = () => {
    return (
        <div className="min-h-screen bg-white">
            {/* Navigation */}
            <nav className="border-b">
                <div className="max-w-6xl mx-auto px-4 py-5">
                    <div className="flex justify-between items-center">
                        <div className="text-2xl font-bold text-gray-900">Dragonero</div>
                        <div className="flex gap-8">
                            <a href="#about" className="text-gray-600 hover:text-gray-900">About</a>
                            <a href="#products" className="text-gray-600 hover:text-gray-900">Products</a>
                            <a href="#contact" className="text-gray-600 hover:text-gray-900">Contact</a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <section className="py-20 bg-gray-50">
                <div className="max-w-6xl mx-auto px-4">
                    <h1 className="text-5xl font-bold text-gray-900 mb-6">
                        Building Tomorrow's Digital Solutions
                    </h1>
                    <p className="text-xl text-gray-600 max-w-2xl mb-8">
                        Dragonero is a technology company specializing in innovative mobile applications
                        and digital solutions that transform how people connect and communicate.
                    </p>
                </div>
            </section>

            {/* Products Section */}
            <section id="products" className="py-16">
                <div className="max-w-6xl mx-auto px-4">
                    <h2 className="text-3xl font-bold text-gray-900 mb-12">Our Products</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="bg-white p-6 rounded-lg border">
                            <Smartphone className="w-8 h-8 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Histo</h3>
                            <p className="text-gray-600 mb-4">
                                A transformative personal growth platform that empowers users through historical wisdom and self-development. Through curated audio/text biographies, inspiring quotes, mindful affirmations, quizzes, and other types of content, Histo helps users learn from history's greatest minds and shape their journey of becoming their best selves.
                            </p>
                            <a
                                href="https://apps.apple.com/app/id6504029365"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-700 inline-flex items-center gap-2"
                            >
                                View on App Store
                                <ExternalLink className="w-4 h-4" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Section */}
            <section id="about" className="py-16 bg-gray-50">
                <div className="max-w-6xl mx-auto px-4">
                    <h2 className="text-3xl font-bold text-gray-900 mb-12">About Dragonero</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="flex flex-col items-center text-center">
                            <Building2 className="w-8 h-8 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Established Company</h3>
                            <p className="text-gray-600">
                                Founded with a vision to create impactful digital solutions
                            </p>
                        </div>
                        <div className="flex flex-col items-center text-center">
                            <Shield className="w-8 h-8 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Security First</h3>
                            <p className="text-gray-600">
                                Committed to protecting user data and privacy
                            </p>
                        </div>
                        <div className="flex flex-col items-center text-center">
                            <Smartphone className="w-8 h-8 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">Innovation</h3>
                            <p className="text-gray-600">
                                Creating next-generation mobile experiences
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className="py-16">
                <div className="max-w-6xl mx-auto px-4">
                    <h2 className="text-3xl font-bold text-gray-900 mb-12">Contact Us</h2>
                    <div className="flex justify-center">
                        <div className="flex items-center gap-2">
                            <Mail className="w-5 h-5 text-blue-600" />
                            <a href="mailto:contact@dragonero.com" className="text-gray-600 hover:text-gray-900">
                                contact@dragonero.com
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="border-t py-8">
                <div className="max-w-6xl mx-auto px-4">
                    <p className="text-center text-gray-600">
                        © {new Date().getFullYear()} Dragonero. All rights reserved.
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default DragoneroBusiness;